import React from 'react'
import {useState, useEffect } from "react";
import './Footer.css'


export default function Footer() {

    const [year, setYear] = useState(new Date().getFullYear());

    useEffect(() => {
        // Update the year when the component mounts
        setYear(new Date().getFullYear());
      }, []);

    return (
        <div className="footer-contianer">
            <span>Copyright © 2021 - {year} </span> 
            <span>Lanzarote Art Gallery. Derechos Reservados</span> 
            <br/>
            Made with ♡ by <a href="https://ihm.solutions" target="_blank" rel="noreferrer">iHM Solutions</a>
            <br/>
            REF.{process.env.REACT_APP_CURRENT_GIT_COMMIT.substring(0, 12)}
        </div>
    )
}
