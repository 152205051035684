import React, {useState} from 'react';
import {  Container, 
          Paper, 
          Grid, 
          TextField, 
          Button, 
          ButtonGroup, 
          InputAdornment, 
          InputLabel, 
          Select, 
          MenuItem, 
          Backdrop,
          CircularProgress,
          Tooltip,
          Dialog,
          DialogTitle,
          DialogActions,
          DialogContent,
          DialogContentText,
          FormControl } from '@material-ui/core';
import { DatePicker } from '@material-ui/pickers';
import BackupIcon from '@material-ui/icons/Backup';
import AddPhotoAlternateIcon from '@material-ui/icons/AddPhotoAlternate';
import DeleteIcon from '@material-ui/icons/Delete';
import SendIcon from '@material-ui/icons/Send';
import {HeightRounded, EuroSymbol } from '@material-ui/icons';
import uuidv4 from 'uuid/v4';
import { makeStyles } from '@material-ui/core/styles';


import './App.css';
import AppEnums from './AppEnums';
import Footer from './components/Footer';
import firebase from './firebase';
import { blobToBase64, convertJSONToCSV, WordCount } from './Utils';

import { MailerSend, EmailParams, Sender, Recipient } from "mailersend";

// 3rd Email Sending System based on nodemailer run as background with nextjs
import axios from "axios";


const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));


function App() {

  const classes = useStyles();

  const [authorinfo, setauthorinf]        = useState( CreateEmptyArtistEntry() );
  const [artworks, setartworks]           = useState([CreateEmptyArtworkEntry()]);
  const [errorAuthor, seterrorAuthor]     = useState({});
  const [errorArtworks, setErrorArtworks] = useState([]);
  const [submitmsg, setSubmitMsg]         = useState("");
  const [openbackdrop, setopenbackdrop]   = useState(false);

  // SETTINGS PARAMETES
  const MAXIMUM_FILE_SIZE = 2; // [MBytes]
  // const token = "3c912c49-0296-460a-8c40-a7b291139a7d"; // Mio
  const token = "dc403b05-8e69-443c-bca7-e629efbe8e13"; // Gestion


  const CSV_HEADER_AUTHOR  = 'Nombre; Apellidos; Nombre Artistico; email; Tipo ID; Numero ID; DoB; Descripción; Imagen';  
  // const CSV_HEADER_ARTWORK = 'Nombre; Apellidos; Numero ID; ID; Titulo; Año; Precio; Tipo; Soporte; Técnica; Otros; Alto; Ancho; Profundidad; Descripción; curriculum; Imagen'; 
  const CSV_HEADER2_ARTWORK = 'Nombre; Numero ID; ID; Titulo; Año; Precio; Tipo; Soporte; Técnica; Otros; Alto; Ancho; Profundidad; Descripción; curriculum; Imagen'; 
 

  /**
   * Create an Empty Artist Info Entry
   * @returns empty artist info
   */
  function CreateEmptyArtistEntry(){
    return {
      name: '',
      lastname: '',
      artisticname: '',
      email: '',
      id_type: AppEnums.ID_TYPES[0],
      id_number: '',
      dob: new Date(),
      description: '',
      image: '',
      image_data: ''

    };
  }


  /**
   * Create an Empty Artwork Entry
   * @returns  empty artwork item
   */
  function CreateEmptyArtworkEntry(){
    return {
      id: uuidv4(),
      title:'', 
      year: new Date(),
      price: '',
      type: AppEnums.ARTWORK_TYPES[0], 
      support: '', //AppEnums.MATERIALS_LIST[0].id,
      technique: '', //AppEnums.TECHNIQUES_LIST[0].id,
      other_details: '',
      height: '',
      width: '',
      depth: '',
      description: '',
      exhibitions_cv: '',
      image: '',
      image_data: null
    };
  }


  /**
   * Handle the Changes In the Input fields of the Author Info
   * @param {*} event event with the name field and value to be changed
   */
  function handleArtistChangeInput(input_name, event){

    const tempAuthorInfo = {...authorinfo};

    if(input_name === "dob"){
      // console.log("FECHA")
      // console.log(event)
      tempAuthorInfo[input_name] = event;
    }
    else {
      
      tempAuthorInfo[event.target.name] = event.target.value;
      // setauthorinf(tempAuthorInfo);
      //! DELETEME 
      // console.log(event.target.name, event.target.value)
    }

    setauthorinf(tempAuthorInfo);
    
  }


  /**
   * Handle 
   * @param {*} event 
   */
  function handleArtistImageChange(event){

    if (event.target.files && event.target.files[0]) {

      const imgFile = event.target.files[0];

      // Sanity Check: File is bellow some limit
      if( !isImageSizeValid(imgFile.size) )
      {  
        alert('El tamaño del archivo es ' + (imgFile.size/1024/1024).toFixed(1) 
              + " MB y deberia ser menos de " + MAXIMUM_FILE_SIZE.toFixed(1) + " MB");
        event.target.value = '';
        return;
      }

      // Save the Image Data and the Image URL temporally
      setauthorinf({ ...authorinfo, 
        image: URL.createObjectURL(imgFile),
        image_data: imgFile
      });
  
    }
  }


  /**
   * Add new empty Aartwork entry in the list of artworks
   */
  function AddNewArtWork(){

    setartworks([...artworks, CreateEmptyArtworkEntry()]);

  }


  /**
   * Delete the give Artwork
   * @param {*} id UUID of the selected artwork
   */
  const DeleteArtWork = id => {

    const tempArtworks = [...artworks];
    tempArtworks.splice( tempArtworks.findIndex(value => value.id === id), 1);
    // console.log(tempArtworks);
    setartworks(tempArtworks);
    
  }


  /**
   * Handle the Changes In the Input fields of the Artworks
   * @param {*} index index of the artwork to be modified
   * @param {*} event event with the name field and value to be changed
   */
  function handleArtworkChangeInput(index, event){

    const tempArtworks = [...artworks];

    const attribute = event.target? event.target.name : "year";
  
    // Handle Inputs
    switch (attribute) {
      case "year":
        tempArtworks[index]["year"] = event;
        break;
      case "price": 
        const regex_price = new RegExp(/^\d*\,?\d*$/);
        if (event.target.value === '' || regex_price.test(event.target.value)) {
          tempArtworks[index][event.target.name] = event.target.value;
        }
        break;
      case "height":
      case "width":
      case "depth":
        const re = /^[0-9\b]+$/;
        if (event.target.value === '' || re.test(event.target.value)) {
          tempArtworks[index][event.target.name] = event.target.value;
        }
        break;
      
      default:
        tempArtworks[index][event.target.name] = event.target.value;
        break;
    }
    
    setartworks(tempArtworks); 

  }


  /**
   * Handle Artwork Image Change
   * @param {*} event 
   */
  function handleArtworkImageChange(id, event){

      // console.log("editing foto: ", id);
      // console.log("event: ", event.target.id);
      // console.log("event: ", event.target.id);
      // console.log("arg: ", document.getElementById("selectedFileArtwork")[0].getAttribute('artworkindex') );
      // console.log("arg: ", $(event.target) );
      const link = document.getElementById('selectedFileArtwork');
      let index  = link.getAttribute('artworkindex');

    if (event.target.files && event.target.files[0]) {
      
      let imgFile = event.target.files[0];

      // Sanity Check: File is bellow some limit
      if( !isImageSizeValid(imgFile.size) )
      {  
        alert('El tamaño del archivo es ' + (imgFile.size/1024/1024).toFixed(1) 
              + " MB y deberia ser menos de " + MAXIMUM_FILE_SIZE.toFixed(1) + " MB");
        event.target.value = '';
        return;
      }

      const tempArtworks = [...artworks];
      tempArtworks[index]["image_data"] = imgFile;
      tempArtworks[index]["image"]      = URL.createObjectURL(imgFile);
      setartworks(tempArtworks); 

    }
  }


  /**
   * Validate that all the required inputs for the Artist
   * have been provided
   * 
   * @returns true is valid, false otherwise
   */
  function isAuthorInfoValid(){

    var status = true;

    // Reset the Error List
    seterrorAuthor({});

    // Init Temporal Error Array
    const tempErrors = {};

    // Validate Artist's Name
    if(authorinfo.name === '')
    {
      tempErrors["name"] = "El nombre es requerido."
      status = false;
    }

    // Validate Artist's Lastname
    if(authorinfo.lastname === '')
    {
      tempErrors["lastname"] = "Los apellidos son requeridos."
      status = false;
    }

    // Validate Artist's email
    if(authorinfo.email === '')
    {
      tempErrors["email"] = "El email es requerido."
      status = false;
    }

    // Validate Artist's email format
    // eslint-disable-next-line
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if(authorinfo.email)
    {
      // console.log("validando formato de email.......")
      // console.log("email valido? ", re.test(authorinfo.email))
      if(!re.test(authorinfo.email))
      {
        tempErrors["email"] = "El email no es válido."
        status = false;
        // console.log("Setting the error message")
      }
    }

    // Validate Artist's ID number
    if(authorinfo.id_number === '')
    {
      tempErrors["id_number"] = "El numero del id es requerido."
      status = false;
    }
    
    // Validate Artist's Description
    if(authorinfo.description === '')
    {
      tempErrors["description"] = "La descripción del artista es requerida."
      status = false;
    }

    // Validate Artist's Image
    if(authorinfo.image === "")
    {
      tempErrors["image"] = "La imagen del artista es requerida."
      status = false;
    }

    // Save the errors
    seterrorAuthor(tempErrors);

    // Return Status
    return status;
  }


  /**
   * Validate that all the required inputs for each artwork
   * from the list
   * 
   * @returns true is valid, false otherwise
   */
  function isArtworksValid(){

    var status = true;

    // Reset the Error List
    setErrorArtworks([]);

    // Create Temporal Error Array
    const tempErrors = [];

    // Go through out all the artworks
    for (let index = 0; index < artworks.length; index++) {
      
      // Init temporal error array for the current artwork
      tempErrors[index] = {};

      // Validate Artwork's title
      if(artworks[index].title === '')
      {
        tempErrors[index]["title"] = "El título de la obra es requerido."
        status = false;
      }

      // Validate Artwork's Price
      if(artworks[index].price === '')
      {
        tempErrors[index]["price"] = "El precio de la obra es requerido."
        status = false;
      }

       // Validate Artwork's technique
       if(artworks[index].technique === '')
       {
         tempErrors[index]["technique"] = "La técnica de la obra es requerida."
         status = false;
       }

       // Validate Artwork's canvas
       if(artworks[index].support === '')
       {
         tempErrors[index]["support"] = "El soporte de la obra es requerido."
         status = false;
       }

      // Validate Artwork's height
      if(artworks[index].height === '')
      {
        tempErrors[index]["height"] = "Es necesario."
        status = false;
      }

      // Validate Artwork's width
      if(artworks[index].width === '')
      {
        tempErrors[index]["width"] = "Es necesario."
        status = false;
      }

      // Validate Artwork's depth
      if(artworks[index].type === 'Escultura' && artworks[index].depth === '')
      {
        tempErrors[index]["depth"] = "Es necesario."
        status = false;
      }

      // Validate Artwork's description
      if(artworks[index].description === '')
      {
        tempErrors[index]["description"] = "La obra necesita una descripción."
        status = false;
      }

      // Validate Artwork's description
      if(artworks[index].description !== '')
      {
        if( WordCount(artworks[index].description) > 50)
        {
          tempErrors[index]["description"] = "La descripción de la obra no puede tener más de 50 palabras."
          status = false;
        }
      }

      // Validate Artwork's image
      if(artworks[index].image === "")
      {
        tempErrors[index]["image"] = "La imagen de la obra es requerida."
        status = false;
      }
      
    }

    // Save the errors
    setErrorArtworks(tempErrors);

    // Return the Status
    return status;
  }


  /**
   * Check if the file size is within the allowed limit
   * 
   * @param {*} cur_size file size [bytes]
   * @returns true if it is lower the maximum allowed, false otherwise
   */
  function isImageSizeValid(cur_size){
    if(cur_size/1024/1024 > MAXIMUM_FILE_SIZE ) 
        return false;
    else
      return true;
  }


  /**
   * Submit the form to firebase
   *
   * @param {*} event 
   */
  async function handleSubmitForm(event){

    // Prevent that the submition continue
    event.preventDefault();

    //! DELETE, just for debugging 
    // console.log("Artworks: ", artworks);

    // Create a backdrop to make the user know 
    // we are processing the submit
    setopenbackdrop(true);
    
    // Sanity Check: Author Info - Provided the required fields
    if(!isAuthorInfoValid())
    {
      alert("Faltan campos requeridos en la información del artista.");
      setopenbackdrop(false);
      return;
    }

    // Sanity Check: Artworks Info
    if(!isArtworksValid()){
      alert("Faltan campos requeridos en la información de las obras.");
      setopenbackdrop(false);
      return;
    }
    
    // Destruct Author Info
    const{image, image_data, ...tempAuthor} = authorinfo;

     //! DELETEME
    // console.log("WITHOUT IMAGE")
    // console.log(tempAuthor);

    // const temp = {...tempAuthor, image2: "Hola"};

     //! DELETEME
    // console.log("WITH FAKE IMAGE2")
    // console.log(temp);

     //! DELETEME
    // console.log("IMAGES")
    // console.log(image_data)

    var tmpCSVArtworks = [];

    //! DELETEME
    // console.log("Auto Loging...")

    // Update Status submition
    setSubmitMsg("Connectando con el server...");

    try{
    // Auto-login
    const userCredential =  await firebase.auth().signInWithEmailAndPassword(process.env.REACT_APP_EMAIL, 
                                                                             process.env.REACT_APP_PASSWORD);

    // console.log("User")
    // console.log(userCredential);

    if(!userCredential.user)
    {
      alert("Error Connectando con el Formulario");
      console.error("Problema con el usuario")
      return;
    }

    //! DELETEME
    //console.log("2.B.- Reading...")

    }catch(error){
      alert("Error Connectando con el Formulario");
      console.error(error);
      setopenbackdrop(false);
      return;
    }

    // Dirty Trick to force the authentication with firebase
    await firebase.firestore().collection("test").get();

    // Update Status submition
    setSubmitMsg("Subiendo datos del artista...");

    // Debug
    //console.log("Saving Artista: ");

    // Upload the data to firebase
    firebase.firestore().collection("artistas").add(tempAuthor)
    .then(async (docRef) => {

        // Debug
        //console.log("Document written with ID: ", docRef.id);
        
        // Set the Name of the folder to storage all the images
        const storageFolderName = tempAuthor.lastname + "-" +
                                  tempAuthor.name     + "-" + 
                                  tempAuthor.id_number;

        // Update Status submition
        setSubmitMsg("Subiendo foto del artista...");

        // Upload the imagen to the FireStore 
        const storage    = firebase.storage();
        const storageRef = storage.ref();
        const fileRef = storageRef.child(storageFolderName.replace( /\s/g, ''))
                                  .child(image_data.name.replace( /\s/g, ''));
        await fileRef.put(image_data);
        const imageurl = await fileRef.getDownloadURL();

        // Update the document of the author with the image url and the document ID
        await docRef.update({id: docRef.id, image: imageurl});
        
        // Upload Artworks
        for (let index = 0; index < artworks.length; index++) {

           // Update Status submition
          setSubmitMsg("Subiendo obra " + (index + 1) +  " de " + artworks.length);

          // Extract all the data 
          const{image, image_data, ...curArtwork} = artworks[index];

          // Storage Current Artwork on firebase
          const artworkRef = await firebase.firestore()
                                           .collection("obras")
                                           .add({...curArtwork, author: docRef.id });

          // Upload the imagen to the FireStore 
          const fileRef = storageRef.child(storageFolderName.replace( /\s/g, ''))
                                    .child(image_data.name.replace( /\s/g, ''));
          await fileRef.put(image_data);
          const imageurl = await fileRef.getDownloadURL();

          // Update image url of the artwork
          await artworkRef.update({image: imageurl})

          // Reformat some input for the CSV 
          curArtwork.technique = AppEnums.TECHNIQUES_LIST[curArtwork.technique].name_es;
          curArtwork.support   = AppEnums.MATERIALS_LIST[curArtwork.support].name_es;
          curArtwork.year      = curArtwork.year.getFullYear();

          // Storage the current artwork with extra info for the csv
          tmpCSVArtworks.push({ name: tempAuthor.name + ' ' +  tempAuthor.lastname, 
                                // lastname: tempAuthor.lastname, 
                                id_number: tempAuthor.id_number,
                                ...curArtwork, 
                                image: imageurl, 
                               });
        }
        

        //! DELETEME
        // console.log("CSV Artwork");
        // console.log(tmpCSVArtworks);

        setSubmitMsg("Enviando Formulario en CSV");

        // Send email with the CSV files
        // await sendEmail({...tempAuthor,  image: imageurl},  tmpCSVArtworks); // DEPERCATED
        await sendEmailGmail({...tempAuthor,  image: imageurl},  tmpCSVArtworks); 
       
        // Reset Form
        await setauthorinf( CreateEmptyArtistEntry() );
        await setartworks( [CreateEmptyArtworkEntry()] );

        // Utils.NotifyUserSuccess('Notificación','La exposición ha sido creada correctamente.');
        alert("Formulario Enviado Satisfactoriamente");

    })
    .catch((error) => {
        // Utils.NotifyUserError('Notificación','La exposición no ha podido ser creada.');
        alert("Error Enviando el Formulario");
        console.error("Error adding document: ", error);
    })
    .finally( async () => {

      setopenbackdrop(false);
      
      console.log( "Signing Out...")
      await firebase.auth().signOut();
      console.log( "Signed Out")

    } );



  }



  /**
   * METHOD #1: 
   * Send Email via elasticemail [DEPRECATED]
   * 
   * based on:
   * - https://www.smtpjs.com/ [DEPRECATED]
   * - https://elasticemail.com/ [DEPRECATED]
   */
  async function sendEmail(author, artworks){

    // Create the Name
    const full_name = author.name + " " + 
                      author.lastname + "-" + 
                      author.id_number;

    const tmpjson = JSON.stringify([author]);
     //! DELETEME
    // console.log( author)
    const file = CSV_HEADER_AUTHOR + '\r\n' + convertJSONToCSV( tmpjson );
     //! DELETEME
    // console.log( file );
      
    // Create a Author File
    var blob = new Blob(["\ufeff", file], { type: 'text/csv;charset=utf-8;' });
    const blobAuthor64 = await blobToBase64(blob);

    const tmpjsonArtwork = JSON.stringify(artworks);

     //! DELETEME
    // console.log( artworks)

    const fileArtworks = CSV_HEADER2_ARTWORK + '\r\n' + convertJSONToCSV( tmpjsonArtwork );
    
    //! DELETEME
    // console.log( fileArtworks );

    // Create Artworks File
    var blobArtworks = new Blob(["\ufeff", fileArtworks], { type: 'text/csv;charset=utf-8;' });
    const blobArtworks64 = await blobToBase64(blobArtworks);
   
    // Send the email
    window.Email.send({
      //SecureToken : token,
      Host : process.env.REACT_APP_DEPRECATED_EMAIL_SMTP,
      Username : process.env.REACT_APP_DEPRECATED_EMAIL_USER,
      Password : process.env.REACT_APP_DEPRECATED_EMAIL_PWD,
      To : "virtualgalleryshop@gmail.com",
      From : "formulario@lanzaroteartgallery.com",
      Subject : "Formulario de " + full_name,
      Body : "Se adjunta a este email el resultado del formulario de  " + full_name,
      Attachments : [
        {
          name : "autor.csv",
          data : blobAuthor64
        },
        {
          name : "obras.csv",
          data : blobArtworks64
        }
      ]
    }).then(
      message => console.log(message)
    ).catch(
      message => alert(message)
    );
  }



  /**
   * TESTING
   */
  async function test_send_MailerSend(){

    const mailerSend = new MailerSend({
      apiKey: process.env.REACT_APP_EMAIL_API,
    });
    
    const sentFrom = new Sender("formulario@lanzaroteartgallery.com", "Lanzarote Art Gallery");
    
    const recipients = [
      new Recipient("romero.martin.jm@gmail.com", "JuanMa")
    ];
    
    const emailParams = new EmailParams()
      .setFrom(sentFrom)
      .setTo(recipients)
      .setReplyTo(sentFrom)
      .setSubject("This is a Subject")
      .setHtml("<strong>This is the HTML content</strong>")
      .setText("This is the text content");
    
    await mailerSend.email.send(emailParams);

  }



  /**
   * METHOD #3:  [CURRENT]
   * Send Email via Gmail and Nodemailer library
   * 
   * Install: 
   *    npm install nodemailer
   */
  async function sendEmailGmail(author, artworks){


    // Create the Name
    const full_name = author.name + " " + 
                      author.lastname + "-" + 
                      author.id_number;

    const tmpjson = JSON.stringify([author]);

    const file = CSV_HEADER_AUTHOR + '\r\n' + convertJSONToCSV( tmpjson );
      
    // Create a Author File
    // var blob = new Blob(["\ufeff", file], { type: 'text/csv;charset=utf-8;' });
    // const blobAuthor64 = await blobToBase64(blob);

    const tmpjsonArtwork = JSON.stringify(artworks);


    const fileArtworks = CSV_HEADER2_ARTWORK + '\r\n' + convertJSONToCSV( tmpjsonArtwork );
    
    // Create Artworks File
    // var blobArtworks = new Blob(["\ufeff", fileArtworks], { type: 'text/csv;charset=utf-8;' });
    // const blobArtworks64 = await blobToBase64(blobArtworks);

      const response = await axios.post(process.env.REACT_APP_CURRENT_EMAIL_API_URL, {
      from: '"Formulario" <formulariolanzaroteartgallery@gmail.com',  
      to:  "romero.martin.jm@gmail.com",
      subject: "Formulario de " + full_name,
      message: "Se adjunta a este email el resultado del formulario de  " + full_name,
      attachments: [
        {  
          filename: 'author.csv',
          content: file, 
      },
      {  
          filename: 'obras.csv',
          content: fileArtworks,
      },
      ] 
    });
      
    if (response.status === 200) {
      console.log('Email sent successfully!');
    } else {
      console.log('Failed to send email');
    }

  }


  /**
   * This function is to test the email send system using nodemailer and 
   * the smtp server
   */
  async function test_send_email_gmail() {

    const tmpjson = [{"name":"Test 016","lastname":"DELETEME","artisticname":"DELETEME","email":"romero.martin.jm@gmail.com","id_type":"DNI","id_number":"123456789","dob":"2024-10-18T01:56:02.606Z","description":"This is just a test","image":"https://firebasestorage.googleapis.com/v0/b/lanzaroteartgallery-gestion.appspot.com/o/DELETEME-Test016-123456789%2FLogo_Gestion_Lanzarote_Art_.jpg?alt=media&token=c0cf785c-b264-4530-8c0d-1d84aa49b3aa"}] 

    const tmpjsonArtwork = [{"name":"Test 016 DELETEME","id_number":"123456789","id":"8495c94f-4dd6-4ff4-8c02-5a701fc9058d","title":"Test","year":2024,"price":"11","type":"Pintura","support":"Papel","technique":"Resina","other_details":"This is a test","height":"11","width":"11","depth":"","description":"This is just a test","exhibitions_cv":"","image":"https://firebasestorage.googleapis.com/v0/b/lanzaroteartgallery-gestion.appspot.com/o/DELETEME-Test016-123456789%2FLogo_Gestion_Lanzarote_Art_.jpg?alt=media&token=af9de8a6-49ae-481d-8269-1c7912b346ea"}]

    
      
    // Create the Name
    const full_name = "TEST 017 DELETEME - 123456789"
    

    const file = CSV_HEADER_AUTHOR + '\r\n' + convertJSONToCSV( tmpjson );
    
    // Create a Author File
    // var blob = new Blob(["\ufeff", file], { type: 'text/csv;charset=utf-8;' });
    // const blobAuthor64 =  blobToBase64(blob);
     
    const fileArtworks = CSV_HEADER2_ARTWORK + '\r\n' + convertJSONToCSV( tmpjsonArtwork );
    

    // Create Artworks File
    // var blobArtworks = new Blob(["\ufeff", fileArtworks], { type: 'text/csv;charset=utf-8;' });
    // const blobArtworks64 =  blobToBase64(blobArtworks);
    
    const response = await axios.post(process.env.REACT_APP_CURRENT_EMAIL_API_URL, {
      from: '"Formulario" <formulariolanzaroteartgallery@gmail.com',  
      to:  "romero.martin.jm@gmail.com",
      subject: "Formulario de " + full_name,
      message: "Se adjunta a este email el resultado del formulario de  " + full_name,
      attachments: [
        {  
          filename: 'author.csv',
          content: file, //blobAuthor64, 
      },
      {  
          filename: 'obras.csv',
          content: fileArtworks, //blobArtworks64,
      },
      ] 
    });
      
    if (response.status === 200) {
      console.log('Email sent successfully!');
    } else {
      console.log('Failed to send email');
    }
    


  }

  /**
   * FOR TESTING
   * ! delete
   */
   async function testFirebase(){

    const userCredential = await firebase.auth().signInWithEmailAndPassword(process.env.REACT_APP_EMAIL, 
                                                                            process.env.REACT_APP_PASSWORD);

    console.log(firebase.auth().currentUser)

    console.log("2.B.- Reading...")
    await firebase.firestore().collection("test").get();

    console.log("Creating new artist")
    firebase.firestore().collection("test").add({name: "John", lastname: "Smith"})
    .then( async (docRef) => { console.log("Created Artist"); console.log(docRef)})
    .catch( async (error) => console.error(error) )
    .finally( ()=> console.log("signing out"));

  }


  /** 
   * FOR TESTING
   * ! delete
   */
  async function testNewArtWorkStructure(){

    var artistas = new Map();
    var authDoc = await firebase.firestore().collection("artistas").get();

    for( var i = 0; i < authDoc.docs.length; i++){
      // console.log( authDoc.docs[i].data() )
      artistas.set(authDoc.docs[i].data().id, authDoc.docs[i].data())
    }

    console.log(artistas)

     // Dirty Trick to force the authentication with firebase
    let datos = [];
    let artista;
    let obra;
    let csvObra;
    firebase.firestore().collection("obras").get().then(async (querySnapshot) => {

      querySnapshot.docs.map( async (doc) =>  {

        obra    = doc.data();
        artista = artistas.get( obra.author );

        console.log("Artista: " +  doc.data().author  )
        console.log("Nombre: " + artista.name + ' ' + artista.lastname);

        csvObra = {
            nombre: artista.name + ' ' + artista.lastname,
            numero_id: artista.id_number,
            id: obra.id,
            titulo: obra.title, 
            año:  ( obra.year ? obra.year.toDate().getFullYear(): "") ,
            precio: obra.price,
            tipo: obra.type,
            soporte: AppEnums.MATERIALS_LIST[obra.support].name_es,
            tecnica: AppEnums.TECHNIQUES_LIST[obra.technique].name_es,
            otros: obra.other_details,
            alto: obra.height,
            ancho: obra.width,
            profundidad: obra.depth,
            descripcion: obra.description,
            curriculum: obra.exhibitions_cv,
            imagen: obra.image
        };

        datos.push(csvObra);

        console.log( JSON.stringify(csvObra) )

      }); 
    
    } 
   ).finally(
       async () => { 
          const fileArtworks = CSV_HEADER2_ARTWORK + '\r\n' + convertJSONToCSV( JSON.stringify(datos) );

          console.log( fileArtworks )

          let filename  = "artistasDUMP.csv"

              // Create Artworks File
          var blobArtworks = new Blob(["\ufeff", fileArtworks], { type: 'text/csv;charset=utf-8;' });
          // const blobArtworks64 = await blobToBase64(blobArtworks);

            if(window.navigator.msSaveOrOpenBlob) {
                window.navigator.msSaveBlob(blobArtworks, "");
            }
            else{
                const elem = window.document.createElement('a');
                elem.href = window.URL.createObjectURL(blobArtworks);
                elem.download = filename;        
                document.body.appendChild(elem);
                elem.click();        
                document.body.removeChild(elem);
            }
        }
    );

  }


  const [open, setOpen] = React.useState(false);
  const [selectedArtwork, setSelectedArtwork] = useState();


  const handleClickOpen = (artworkID) => {
    setOpen(true);
    console.log(artworkID);
    setSelectedArtwork(artworkID);
  };


  const handleClose = () => {
    setOpen(false);
    setSelectedArtwork(null);
  };

  return (

    <Container className="App">

      <header className="App-header">
        <img src={process.env.PUBLIC_URL + '/logo.png'} className="App-logo" alt="logo" />
      </header>

      <form>

        {/*==================================================================*/}
        {/*=         A U T H O R ' S    I N F O R M A T I O N S             =*/}
        {/*==================================================================*/}

        <h1>Informacion del Artista</h1>

        <Paper elevation={5} style={{paddingLeft:30, paddingRight:30, marginTop: 30}}>

          <Grid container spacing={4}>

            {/*--------------------------------------------------------------*/}
            {/*-                 L E F T   C O L U M N                      -*/}
            {/*--------------------------------------------------------------*/}
            <Grid item xs={6}>
              <Grid container spacing={4}>

                <Grid container spacing={4}> 
                  <Grid  item xs={6}> 
                    <TextField
                      fullWidth
                      required
                      name="name"
                      type="text"
                      label="Nombre"
                      variant="filled"
                      margin="normal"
                      value={authorinfo.name} 
                      onChange = {event => handleArtistChangeInput("name", event) }
                      error={ errorAuthor["name"]}
                      helperText= { errorAuthor["name"] ? errorAuthor["name"] : '' }
                    />
                  </Grid>
                  <Grid  item xs={6}> 
                    <TextField
                      fullWidth
                      required
                      name="lastname"
                      type="text"
                      label="Apellidos"
                      variant="filled"
                      margin="normal"
                      value={authorinfo.lastname}
                      onChange = {event => handleArtistChangeInput("lastname", event) }
                      error={ errorAuthor["lastname"]}
                      helperText= { errorAuthor["lastname"] ? errorAuthor["lastname"] : '' }
                    />
                  </Grid>
                </Grid>
  

                <Grid container spacing={4}>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      name="artisticname"
                      type="text"
                      label="Nombre Artístico"
                      variant="filled"
                      margin="normal"
                      value={authorinfo.artisticname}
                      onChange = {event => handleArtistChangeInput("artisticname", event) }
                      
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      required
                      name="email"
                      type="email"
                      label="email"
                      variant="filled"
                      margin="normal"
                      value={authorinfo.email}
                      onChange = {event => handleArtistChangeInput("email", event) }
                      error={ errorAuthor["email"]}
                      helperText= { errorAuthor["email"] ? errorAuthor["email"] : '' }
                    />
                  </Grid>
                </Grid>

                <Grid container spacing={4}>
                  <Grid item xs={4}>
                    <FormControl variant="filled" fullWidth style={{ marginTop:15, marginBottom:15}}>
                        <InputLabel required> Tipo de ID </InputLabel>
                        <Select
                            labelId="id_type"
                            id="id_type"
                            name="id_type"
                            value={authorinfo.id_type}
                            onChange = {event => handleArtistChangeInput("id_type", event) }
                        >
                            { 
                              AppEnums.ID_TYPES.map( e => <MenuItem value={e}>{e}</MenuItem>)
                            } 
                        </Select>
                      </FormControl>
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      required
                      name="id_number"
                      type="text"
                      label="Numero ID"
                      variant="filled"
                      margin="normal"
                      value={authorinfo.id_number}
                      onChange = {event => handleArtistChangeInput("id_number", event) }
                      error={ errorAuthor["id_number"]}
                      helperText= { errorAuthor["id_number"] ? errorAuthor["id_number"] : '' }
                    />
                  </Grid>
                  <Grid item xs={4}>
                      <DatePicker 
                        autoOk='true' 
                        variant="inline"
                        format="dd/MM/yyyy"
                        id="id_dateCreated"
                        label="Fecha de Nacimiento" 
                        name="dob"
                        required
                        style={{'marginBottom':'10px', marginTop:20, background:'#e8e8e8'}}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        // value= {dateofbirth} 
                        // onChange = {(data) => { console.log(data); setdateofbirth(data); } }
                        value={authorinfo.dob}
                        onChange = {event => handleArtistChangeInput("dob", event) }
                      />   
                  </Grid>
                  
                </Grid>

                <TextField
                  fullWidth
                  required
                  name="description"
                  type="text"
                  label="Descripción Del Artista"
                  variant="filled"
                  margin="normal"
                  multiline
                  minRows={11}
                  maxRows={11}
                  value={authorinfo.description}
                  onChange = {event => handleArtistChangeInput("description", event) }
                  error={ errorAuthor["description"]}
                  helperText= { errorAuthor["description"] ? errorAuthor["description"] : '' }
                />
              </Grid>
            </Grid>

            {/*--------------------------------------------------------------*/}
            {/*-                R I G T H   C O L U M N                     -*/}
            {/*--------------------------------------------------------------*/}
            <Grid item xs={6}>
              <Grid container spacing={4}>

                <Grid item xs={12}>
                  <div className={errorAuthor["image"] ? "UploadContainer UploadContainerError" : "UploadContainer"}>
                    {authorinfo.image ?
                      <img src={authorinfo.image} alt="imagen del autor" />
                    :
                      <div style={{textAlign: 'center'}}>
                        Subir Imagen <br/>
                        (máximo 2MB)
                      </div>
                    } 
                  </div>
                  
                  <div className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error Mui-required" style={{color:'red'}}>
                    { errorAuthor["image"] ? errorAuthor["image"] : ""}
                  </div>
                  
                  <input type="file" id="selectedFile" hidden onChange={ handleArtistImageChange } />
                  <Button variant="contained" 
                          className="UploadImageButton" 
                          onClick={ e => { document.getElementById('selectedFile')?.click(); } } 
                          style={{'marginTop':'20px'}}
                  > 
                    <BackupIcon style={{'paddingRight':'5px'}} />  
                    Subir Foto 
                  </Button>

                  <div className="UploadInfoContainer">
                    <strong>IMPORTANTE!</strong> 
                    <br/>
                    <p>Por favor, la imagen tiene que ser como máximo de 2MB</p>
                  </div>
                </Grid>
              </Grid>
            </Grid>

          </Grid>
        </Paper>

        {/*==================================================================*/}
        {/*=          O B R A S     I N F O R M A T I O N S                 =*/}
        {/*==================================================================*/}

        <div className="separator"></div>

        <h1>Obras ({artworks ? artworks.length : 0})</h1> 

        <div style={{textAlign: 'left'}}>
          <span>Puede añadir más obras a la lista, has click sobre el boton </span>
          <Button  color="primary" variant="contained" style={{'marginLeft': 10, 'marginRight': 10}}>
            <AddPhotoAlternateIcon/>
          </Button>
          a la derecha de cada obra, o has click al boton del final de la lista. 
        </div>

        <Paper elevation={5} style={{paddingLeft:30, paddingRight:30, marginTop: 30, marginBottom: 60}}>

        { 
          artworks?.map( (curArtwork, index) => { 

            return <div style={{marginTop: 40, marginBottom: 40}}  key={curArtwork.id} >
            
              <Grid container spacing={4} >

                {/* Ordering Number */}
                <Grid item xs={1}> 
                  <strong style={{marginTop: 30}}> {index + 1}. </strong> 
                  <div style={{marginTop: 20}}>
                    <ButtonGroup  variant="contained" orientation="vertical"
                                  color="primary"
                                  aria-label="vertical outlined primary button group">
                      <Tooltip title="Eliminar la obra de la lista" placement="right" arrow >
                        {/* <Button disabled={artworks.length === 1} onClick={() => DeleteArtWork(curArtwork.id) } > */}
                        <Button disabled={artworks.length === 1} onClick={ () =>{ handleClickOpen(curArtwork.id) }} >
                          <DeleteIcon/>
                        </Button>
                      </Tooltip>

                      <Dialog
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                      >
                        <DialogTitle id="alert-dialog-title">
                          {"Confirmar Eliminación"}
                        </DialogTitle>
                        <DialogContent>
                          <DialogContentText id="alert-dialog-description">
                          ¿Esta seguro que quiere eliminar la obra de la lista?
                          </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                          <Button onClick={handleClose}>Cancelar</Button>
                          <Button onClick={()=> { DeleteArtWork(selectedArtwork); handleClose(); } } autoFocus>Eliminar</Button>
                        </DialogActions>
                      </Dialog>

                      <Tooltip title="Añadir una obra nueva a la lista" placement="right" arrow >
                        <Button onClick = { AddNewArtWork } >
                          <AddPhotoAlternateIcon/>
                        </Button>
                      </Tooltip>
                    </ButtonGroup>
                  
                  </div>
                </Grid>

                {/*--------------------------------------------------------------*/}
                {/*-                 L E F T   C O L U M N                      -*/}
                {/*--------------------------------------------------------------*/}
                <Grid item xs={6}>

                  <Grid container spacing={4}>

                    <TextField
                      fullWidth
                      required
                      name="title"
                      type="text"
                      label="Titulo de la Obra"
                      variant="filled"
                      margin="normal"
                      value={curArtwork.title}
                      onChange = {event => handleArtworkChangeInput(index, event) }
                      error={ errorArtworks[index]?.title}
                      helperText= { errorArtworks[index]?.title ? errorArtworks[index]["title"] : '' }
                    />


                    <Grid container spacing={4}>

                      <Grid item xs={6}>
                        <DatePicker 
                          views={["year"]}
                          autoOk='true' 
                          variant="inline"
                          format="yyyy"
                          id="year"
                          label="Año" 
                          name="year"
                          openTo= "year"
                          required
                          // dense="true"
                          style={{'marginBottom':'10px', marginTop:20, background:'#e8e8e8'}}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          value={curArtwork.year}
                          onChange = {event => handleArtworkChangeInput(index, event) }
                        />   
                      </Grid>

                      <Grid item xs={6}>
                      <TextField
                          fullWidth
                          required
                          name="price"
                          label="Precio"
                          variant="filled"
                          margin="normal"
                          InputProps={{
                             startAdornment: <InputAdornment position="start"><EuroSymbol className="canvas_input_icon"/> </InputAdornment>,
                          }}
                          value={curArtwork.price}
                          onChange = {event => handleArtworkChangeInput(index, event) }
                          error={ errorArtworks[index]?.price}
                          helperText= { errorArtworks[index]?.price ? errorArtworks[index]["price"] : '' }
                        />
                      </Grid>


                    </Grid>

                    <Grid container spacing={4}>

                      <Grid item xs={4}>
                        <FormControl variant="filled" fullWidth style={{ marginTop:10, marginBottom:10}}>
                          <InputLabel required> Tipo </InputLabel>
                          <Select
                              labelId="id_artwork_type"
                              id="id_artwork_type"
                              name = "type"
                              // value={ this.state.curCuadro?.material || AppEnums.MATERIALS_LIST[0].id}
                              // onChange={ (e) => this.handleCuadroChange({...e, target: {...e.target, id: "id_canvas_material"} }) }
                              //defaultValue={AppEnums.ARTWORK_TYPES[0]}
                              // disabled={this.state.editMode ? false : true}
                              value = {curArtwork.type}
                              onChange = {event => handleArtworkChangeInput(index, event) }
                          >
                              { 
                                AppEnums.ARTWORK_TYPES.map( e => <MenuItem value={e}>{e}</MenuItem>)
                              } 
                          </Select>
                        </FormControl>
                      </Grid>

                      <Grid item xs={4}>
                        <FormControl variant="filled" fullWidth style={{ marginTop:10, marginBottom:10}}>
                          <InputLabel required> Técnica </InputLabel>
                          <Select
                              labelId="id_artwork_technique"
                              id="id_artwork_technique"
                              name="technique"
                              value={curArtwork.technique}
                              onChange = {event => handleArtworkChangeInput(index, event) }
                          >
                              { 
                                AppEnums.TECHNIQUES_LIST.map( e => <MenuItem value={e.id}>{e.name_es}</MenuItem>)
                              } 
                          </Select>
                        </FormControl>
                        <div className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error Mui-required">
                              { errorArtworks[index]?.technique ? errorArtworks[index]?.technique : ""}
                        </div>
                      </Grid>

                      <Grid item xs={4}>
                        <FormControl variant="filled" fullWidth style={{ marginTop:10, marginBottom:10}}>
                          <InputLabel required> Soporte </InputLabel>
                          <Select
                              labelId="id_artwork_canvas"
                              id="id_artwork_canvas"
                              name="support"
                              value={curArtwork.support}
                              onChange = {event => handleArtworkChangeInput(index, event) }
                          >
                              { 
                                AppEnums.MATERIALS_LIST.map( e => <MenuItem value={e.id}>{e.name_es}</MenuItem>)
                              } 
                          </Select>
                        </FormControl>
                        <div className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error Mui-required">
                         { errorArtworks[index]?.support ? errorArtworks[index]?.support : ""}
                        </div>
                      </Grid>
                      
                      
                    </Grid>
      
                    <TextField
                      fullWidth
                      name="other_details"
                      type="text"
                      label="Otros Detalles Técnicos"
                      variant="filled"
                      margin="normal"
                      value={curArtwork.other_details}
                      onChange = {event => handleArtworkChangeInput(index, event) }
                    />

                    <Grid container spacing={4}>
                      <Grid item xs={4}>
                        <TextField
                          fullWidth
                          required
                          name="height"
                          // component={TextField}
                          label="Alto (cm)"
                          variant="filled"
                          margin="normal"
                          InputProps={{
                             startAdornment: <InputAdornment position="start"><HeightRounded className="canvas_input_icon"/> </InputAdornment>,
                          }}
                          value={curArtwork.height}
                          onChange = {event => handleArtworkChangeInput(index, event) }
                          error={ errorArtworks[index]?.height}
                          helperText= { errorArtworks[index]?.height ? errorArtworks[index]["height"] : '' }
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <TextField
                          fullWidth
                          required
                          name="width"
                          // component={TextField}
                          type="text"
                          label="Ancho (cm)"
                          variant="filled"
                          margin="normal"
                          InputProps={{
                            startAdornment: <InputAdornment position="start"><HeightRounded className="canvas_input_icon canvas_input_icon_90"/> </InputAdornment>,
                          }}
                          value={curArtwork.width}
                          onChange = {event => handleArtworkChangeInput(index, event) }
                          error={ errorArtworks[index]?.width}
                          helperText= { errorArtworks[index]?.width ? errorArtworks[index]["width"] : '' }
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <TextField
                          fullWidth
                          required
                          name="depth"
                          type="text"
                          label="Profundidad (cm)"
                          variant="filled"
                          margin="normal"
                          InputProps={{
                            startAdornment: <InputAdornment position="start"><HeightRounded className="canvas_input_icon canvas_input_icon_45"/> </InputAdornment>,
                          }}
                          value={curArtwork.depth}
                          onChange = {event => handleArtworkChangeInput(index, event) }
                          disabled = {curArtwork.type === "Escultura" ? false : true} 
                          error={ errorArtworks[index]?.depth}
                          helperText= { errorArtworks[index]?.depth ? errorArtworks[index]["depth"] : 'Solo para esculturas' }
                        />
                      </Grid>
                    </Grid>

                    <TextField
                      fullWidth
                      required
                      name="description"
                      type="text"
                      label="Descripción De La Obra (max 50 palabras)"
                      variant="filled"
                      margin="normal"
                      multiline
                      minRows={8}
                      maxRows={8}
                      value={curArtwork.description}
                      onChange = {event => handleArtworkChangeInput(index, event) }
                      error={ errorArtworks[index]?.description}
                      helperText= { errorArtworks[index]?.description ? errorArtworks[index]["description"] : '' }
                    />

                    <TextField
                      fullWidth
                      name="exhibitions_cv"
                      type="text"
                      label="Exposiciones de la Obra"
                      variant="filled"
                      margin="normal"
                      multiline
                      minRows={8}
                      maxRows={8}
                      value={curArtwork.exhibitions_cv}
                      onChange = {event => handleArtworkChangeInput(index, event) }
                    />

                  </Grid>

                </Grid>

                {/*--------------------------------------------------------------*/}
                {/*-                R I G T H   C O L U M N                     -*/}
                {/*--------------------------------------------------------------*/}
                <Grid item xs={5}>

                  <Grid container spacing={4}>

                    <Grid item xs={12}>
                      <div className={errorArtworks[index]?.image ? "UploadArtworkContainer UploadContainerError" : "UploadArtworkContainer" }>
                        { curArtwork.image ?
                          <img src={curArtwork.image} alt="imagen de la obra"/>
                        :
                          <div style={{textAlign: 'center'}}>
                            Subir Imagen <br/>
                            (máximo 2MB)
                          </div>
                        } 
                      </div>
                      <div className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error Mui-required">
                        { errorArtworks[index]?.image ? errorArtworks[index]?.image : ""}
                      </div>
                      <input type="file" name="selectedFileArtwork"  id="selectedFileArtwork" hidden onChange={(e) => { handleArtworkImageChange(curArtwork.id, e) }}/>
                      <Button variant="contained" 
                              className="UploadImageButton" 
                              onClick={ e => { 
                                var tmpElement = document.getElementById('selectedFileArtwork');
                                tmpElement?.setAttribute("artworkIndex", index);
                                tmpElement?.click();
                              } }
                              style={{'marginTop':'20px'}}
                        > 
                        <BackupIcon style={{'paddingRight':'5px'}} />  
                        Subir Foto 
                      </Button>
                      <div className="UploadInfoContainer">
                        <strong>IMPORTANTE!</strong> 
                        <br/>
                        <p>Por favor, la imagen tiene que ser como máximo de 2MB</p>
                      </div>
                    </Grid>

                  </Grid>

                </Grid>

                {/* Actions Toolbar */}
                {/* <Grid item xs={1}>  */}
                  
                {/* </Grid> */}

              </Grid>

            </div>

          })
        }
        
        <Grid container spacing={4}>
          <Grid item xs={1}>
          </Grid>
          <Grid item xs={11} style={{paddingLeft: 0, paddingBottom: 45}}>
            <Button  color="primary" variant="contained" onClick = { AddNewArtWork }>
              <AddPhotoAlternateIcon/> Añadir una obra más
            </Button>
          </Grid>
        </Grid>
        
        </Paper>



        <Button 
          variant="contained" 
          color="primary" 
          type="submit" 
          onClick={handleSubmitForm}
          endIcon={<SendIcon/>}>
            Enviar
        </Button>

        {/* <Button 
          variant="contained" 
          color="secondary" 
          onClick={testNewArtWorkStructure}
          endIcon={<SendIcon/>}>
            Test
        </Button> */}

        {/* <Button 
          variant="contained" 
          color="secondary" 
          style={{'marginLeft':'10px'}}
          onClick={test_send_email_gmail}
          endIcon={<SendIcon/>}>
            Test Email 
        </Button>  */}


      </form>

      <Footer/>
      
      <Backdrop className={classes.backdrop} open={openbackdrop}>
        <CircularProgress color="inherit"/>
        { submitmsg }
      </Backdrop>

    </Container>

  );
}

export default App;